<template>
  <div
    class="top-full bg-gray-800 text-white py-3 left-1/3 row-span-3 col-span-3"
  >
    <p>Copyright © Ettouibia Salma 2022.</p>
  </div>
</template>

<script>
export default {
  name: "page-footer",
};
</script>

<style lang="scss" scoped></style>
